<template>
  <div
    v-if="banner"
    class="alert alert-warning alert-dismissible fade show"
    role="alert"
  >
    <h3 class="alert-heading">{{ banner["header"] }}</h3>
    <p>
      {{ banner["message"] }}
    </p>
    <hr />
    <p class="mb-0">
      <a href="https://disonds.com" target="_blank" rel="author noopener"
        >DiSonDS</a
      >, {{ getDateDDMMYYYY(banner["creation_date"]["$date"]) }}
    </p>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    banner: {}
  },
  methods: {
    getDateDDMMYYYY(value) {
      return dayjs(value).format("DD.MM.YY");
    }
  }
};
</script>

<style lang="scss" scoped>
@media (prefers-color-scheme: dark) {
  a {
    color: #809fff;
  }
}
</style>
