<template>
  <div class="promo-codes">
    <template v-if="store.state.error"></template>
    <template v-else>
      <PromoCodesBanner v-bind:banner="store.state.banner" />
      <PromoCodesFilter />
    </template>

    <div v-if="store.state.error">
      <PromoCodesErrorHandler v-bind:error="store.state.error" />
    </div>
    <div row row-cols-2 row-cols-md-3 v-else>
      <Suspense timeout="0">
        <template #default>
          <PromoCodesItem
            v-for="(promocode, index) in store.state.visiblePromoCodes"
            v-bind:key="promocode._id"
            v-bind:promocode="promocode"
            v-bind:index="index"
          />
        </template>
        <template #fallback>
          <PromoCodesLoader />
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, defineAsyncComponent } from "vue";
import { store } from "@/store/store";
import PromoCodesBanner from "@/components/PromoCodesBanner.vue";
import PromoCodesFilter from "@/components/PromoCodesFilter.vue";
import PromoCodesLoader from "@/components/loaders/PromoCodesLoader.vue";
import PromoCodesErrorHandler from "@/components/errors/PromoCodesErrorHandler.vue";

const PromoCodesItem = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "promoCodesItem" */
    "@/components/PromoCodesItem.vue"
  )
);

export default {
  name: "Promo codes container",
  async setup() {
    // infinity scroll
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        nextSlice();
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    function nextSlice() {
      if (store.tempPromocodesLength >= store.filteredPromoCodesLength) {
        store.actions.zeroOutSlices();
        console.log("Promocodes is over");
        return;
      }
      store.actions.loadMore();
    }

    // api request
    await store.actions.getPromoCodesFromApi();

    return {
      store,
      nextSlice
    };
  },
  components: {
    PromoCodesErrorHandler,
    PromoCodesBanner,
    PromoCodesFilter,
    PromoCodesLoader,
    PromoCodesItem
  }
};
</script>
