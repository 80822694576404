<template>
  <div
    class="accordion pj-promocode-filter"
    style="color: white"
    id="accordionFilter"
    v-if="store.state.allPromoCodes"
  >
    <div class="accordion-item" style="border: none">
      <h2 class="accordion-header" id="flush-headingOne">
        <button
          class="accordion-button collapsed pj-filter-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseOne"
          aria-expanded="true"
          aria-controls="flush-collapseOne"
        >
          Фильтры
        </button>
      </h2>
      <div
        id="flush-collapseOne"
        class="accordion-collapse collapse show"
        aria-labelledby="flush-headingOne"
        data-bs-parent="#accordionFilter"
      >
        <div class="accordion-body pj-filter-body">
          <form @change="onChangeFilter">
            <fieldset id="pizzeria" class="row mb-3">
              <legend class="col-form-label col-sm-2 pt-0">Выбрать пиццерию:</legend>
              <div class="col-sm-10">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="pizzeria"
                    id="filterPJPromoCodes"
                    v-model="pizzeria"
                    value="pj"
                    checked
                  />
                  <label class="form-check-label" for="filterAllPromoCodes">
                    Джонатан Дуайт Джонс
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="pizzeria"
                    id="filterDDPromocodes"
                    v-model="pizzeria"
                    value="dd"
                    disabled
                  />
                  <label class="form-check-label" for="filterGoodPromocodes">
                    Маврикийский дронт, или додо (лат. Raphus cucullatus) (*скоро станет доступна*)
                  </label>
                </div>
                * Разгадывайте ребусы, мы вынуждены не использовать названия
              </div>
            </fieldset>

            <fieldset id="category" class="row mb-3">
              <legend class="col-form-label col-sm-2 pt-0">Показать промокоды:</legend>
              <div class="col-sm-10">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="category"
                    id="filterAllPromoCodes"
                    v-model="category"
                    value="all"
                    checked
                  />
                  <label class="form-check-label" for="filterAllPromoCodes"> Все </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="category"
                    id="filterGoodPromocodes"
                    v-model="category"
                    value="good"
                  />
                  <label class="form-check-label" for="filterGoodPromocodes">
                    Только с подарками
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="category"
                    id="filterDiscountPromocodes"
                    v-model="category"
                    value="discount"
                  />
                  <label class="form-check-label" for="filterDiscountPromocodes">
                    Только со скидкой
                  </label>
                </div>
              </div>
            </fieldset>

            <fieldset id="sortBy" class="row mb-3">
              <legend class="col-form-label col-sm-2 pt-0">Отображать сначала:</legend>
              <div class="col-sm-10">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="sortBy"
                    id="sortByNew"
                    v-model="sortBy"
                    value="new"
                  />
                  <label class="form-check-label" for="sortByNew"> Новые </label>
                </div>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="sortBy"
                    id="sortByCheap"
                    v-model="sortBy"
                    value="cheap"
                  />
                  <label class="form-check-label" for="sortByCheap"> Дешевые </label>
                </div>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="sortBy"
                    id="sortByExpensive"
                    v-model="sortBy"
                    value="expensive"
                  />
                  <label class="form-check-label" for="sortByExpensive"> Дорогие </label>
                </div>
              </div>
            </fieldset>

            <fieldset id="sortBy" class="row mb-3">
              <legend class="col-form-label col-sm-2 pt-0">
                Планируемая сумма заказа (руб.):
              </legend>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col">
                    <input
                      type="number"
                      min="0"
                      class="form-control pj-form-control"
                      placeholder="От"
                      v-model="minAmountFrom"
                    />
                    <small class="form-text text-white">от</small>
                  </div>
                  <div class="col">
                    <input
                      type="number"
                      min="0"
                      class="form-control pj-form-control"
                      placeholder="До"
                      aria-describedby="amountToHelpBlock"
                      v-model="maxAmountTo"
                    />
                    <small class="form-text text-white">до</small>
                  </div>
                </div>
              </div>
            </fieldset>

            Вам подходит промокодов:
            <span class="badge bg-success align-middle">
              {{ store.getters.filteredPromoCodesLength }}
            </span>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store/store";
import { ref } from "vue";
export default {
  setup() {
    const pizzeria = ref();
    const category = ref("all");
    const sortBy = ref("new");
    const minAmountFrom = ref(store.getters.minAmountFrom);
    const maxAmountTo = ref(store.getters.maxAmountTo);

    function onChangeFilter() {
      store.actions.filterPromocodes(
        category.value,
        sortBy.value,
        minAmountFrom.value,
        maxAmountTo.value
      );
    }

    return {
      store,

      pizzeria,
      category,
      sortBy,
      minAmountFrom,
      maxAmountTo,

      onChangeFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
$filter-primary-lt: #779FA1;
$filter-primary-dark-lt: #5C8284;
$filter-primary-light-lt: #9FBBBC;

$filter-primary-dt: $filter-primary-dark-lt;
$filter-primary-dark-dt: #435F60;
$filter-primary-light-dt: $filter-primary-lt;

.pj-promocode-filter {
  margin-bottom: 1.5rem;
}
.pj-filter-button {
  outline: none !important;
  box-shadow: none !important;
  background-color: $filter-primary-lt;
  color: white;
}
.pj-filter-body {
  background-color: $filter-primary-dark-lt;
}
.form-check-input:checked {
  background-color: $filter-primary-lt;
  border-color: $filter-primary-lt;
}
.pj-form-control {
  border-color: $filter-primary-light-lt;
  background-color: $filter-primary-lt;
  color: white;
}
.pj-alert {
  background-color: #ffffff;
  color: black;
  text-align: center;
  margin-bottom: 0;
}

.accordion-item {
  background-color: $filter-primary-lt;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

@media (prefers-color-scheme: dark) {
  .pj-filter-button {
    background-color: $filter-primary-dt;
    color: white;
  }
  .pj-filter-body {
    background-color: $filter-primary-dark-dt;
  }
  .form-check-input:checked {
    background-color: $filter-primary-dt;
    border-color: $filter-primary-dt;
  }
  .accordion-item {
    background-color: $filter-primary-dt;
  }
  .pj-form-control {
    border-color: $filter-primary-light-dt;
    background-color: $filter-primary-dt;
    color: white;
  }
  .pj-alert {
    color: white
  }
}
</style>
