<template>
  <div class="alert alert-danger" role="alert">
    <h4 class="alert-heading">Ошибка</h4>
    <p>
      Не удалось получить промокоды, попробуйте позже 😥
    </p>
    <hr />
    <p class="mb-0">
      {{ error.message }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    error: {}
  }
};
</script>
